*{
  margin: 0;
  padding: 0;
}
.navbar{
  position: sticky;
  z-index: 6;
  padding: 0;
}
.profilimg{
  width: 77px;
  height: 75px;
  border-radius: 50%;
}
.navbar-brand{
  margin: 0;
}
#namesurname{
  font-size: 20px;
  font-weight: bold;
  margin-left: 30px;
}

.navbar-collapse{
  justify-content: end;
}

ul{
  font-weight: bold;
  color: black;
  font-size: 20px;
}

main{
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(Img/ИЗУЧЯЕМ\ КОМНАТЫ.png);
  background-position: center;
  background-repeat:cover;
}
.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.heading,.headerp{
  text-align: center;
  width: 900px;
  margin: 20px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
h1{
  font-size: 60px;
  font-weight: bold;
}

.headerp{
  font-size: 25px;
  line-height: 40px;
}
a{
  color: white;
  text-decoration: none;
  margin: 15px;
}
a:hover{
  color: rgb(199, 190, 190);
}
left-nav{
  position: relative;
}
button{
  background-color: blueviolet;
  font-weight: bold;
  padding: 10px 40px;
  font-size: 17px;
  border: none;
  border-radius: 10px;
  color: white;
}
.navbar-toggler{
  position: relative;
}
.dropMenu{
  position: absolute;
  right: 0;
  top: 35px;
  height: auto;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.drop{
  border: 2px solid rgb(201, 199, 199);
  text-decoration: none;
  list-style: none;
  width: 100%;
  padding: 20px;
  background-color: white;
  
}
.dropLink{
  color: black;
}
.drop:hover{
  background-color: rgb(243, 242, 242);
}


.icon-container{
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 250px;
  background-color: white;
  padding-top: 20px;
  border: none;
  border-radius: 10px;
  width: 60px;
  height: 250px;
}
.icon{
  width: 30px;
  height: 30px;
  align-items: center;
}
.sections{
  width: 100%;
  background-color: rgb(241, 241, 241);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.myskills{
  width: 40%;
}
.gettoknowme{
  width: 80%;
}

.aboutme{
  padding-top: 100px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Cambria, Cochin, Georgia,serif;
  text-align: center;
}
.gettoknowme-myskills{
  display: flex;
  margin-top: 70px;
  width: 90%;
  height: 550px;
  padding: 10px;
}


.aboutp {
  font-size: 25px;
  padding-top: 40px;
  font-family: Cambria, Cochin, Georgia,serif;
  color: rgb(90, 90, 90);
}
h3{
  font-size: 30px;
  font-family: Cambria, Cochin, Georgia,serif;
  padding-left: 20px;
}
.gettoknowmep{
  font-family: Cambria, Cochin, Georgia,serif;
  font-size: 18px;
  line-height: 30px;
  padding: 0 20px;
  color: rgb(90, 90, 90);

}
.button-container{
  display: flex;
  margin: 10px;
  flex-wrap: wrap;
}
.skills{
  background-color: rgb(209, 208, 208);
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  font-size: 20px;
  color: rgb(59, 59, 59);
}
.project{
  width: 85%;
  display: flex;
  margin: 70px 0;
}
.projectimgDiv{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project3img,.project2img,.project1img{
  width: 90%;
  border: 5px solid gray;
  border-radius: 10px;
}
.detail{
  width: 48%;
  padding: 10px;
}
#section-3{
  background-color: white;
}
.form-container{
  width: 800px;
  height: 930px;
  background-color: white;
  padding: 50px;
  display: block;
  margin-right: auto;
  margin: auto;
  border: none;
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 0 0 10px 1.2px rgb(95, 92, 92);
}
label{
  font-size: 20px;
  margin-left: 50px;
  color: rgb(82, 82, 82);

}
input, textarea{
  padding: 25px;
  width: 80%;
  margin: 35px;
  background-color: rgb(241, 240, 240);
  border: none;
  border-radius: 10px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
footer{
  background-color: black;
  padding: 0 50px;
}
.footer1{
  height: 200px;
  color: white;
  display: flex;
  padding: 30px;
  justify-content: space-between;
}

.icon-footer{
 background-color: white;
 width: 30px;
 height: 30px;
 align-items: center;
}

.footer2{
  background-color: black;
  color: white;
  text-align: center;
  height: 10px;
  padding: 40px;
  display: flex;
  justify-content: center;
}
.footername{
  color: white;
}
.copyright{
  width: 10px;
  height: 10px;
  background-color: white;
  margin-right: 6px;
}

.mainContainer{
  width: 100%;;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contacttContainer{
  width: 50%;
  height: 360px;
  box-shadow: 0 0 10px 1.2px rgb(95, 92, 92);
  padding: 35px;
  font-size: 20px;
  color: rgb(46, 46, 46);
}
.linkedinLink,.githubLink{
  width: 170px;
  height: 50px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 25px;
}
.linkedinLink:hover{
  background-color: #5db0f3;
}
.githubLink:hover{
  background-color: rgb(71, 71, 71);
}
.fa-eye{
  margin: 5px;
}
.linkedinLink{
  background-color: #2196f3;
}.githubLink{
  background-color: black;
}
.messageSend{
  width: 500px;
  height: 150px;
  box-shadow: 0 0 10px 1.2px rgb(95, 92, 92);
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 40px;
  background-color: white;
  font-size: 20px;
}

@media (max-width: 576px) {
  .heading{
    font-size: 50px;
    font-weight: bold;
    width: 300px;
  }
  .headerp{
    width: 300px;
  }
  .aboutme{
    font-size: 50px;
    font-weight: bold;
    width: 300px;
  }
  .gettoknowme-myskills{
    align-items: center;
    flex-direction: column;
    width: auto;
    height: auto;
  }
  .gettoknowme{
    margin-bottom: 50px;
  }
  h3{
    margin: 20px;
  }
  .myskills{
    width: 80%;
    padding-bottom: 50px;
  }
  .project{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
  }
  .detail{
    width: 200px;
    height: auto;
    font-size: 8px;

  }
  .detailsheader{
    font-size: 14px;
    width: 100%;
    margin: 0;
  }
  .project1p{
    width: 100%;
    margin: 3px;
  }
  .projectimgDiv{
    width: 200px;
  }
  .projBtn{
    height: 30px;
    font-size: 10px;
    width: 120px;
    margin: 0;
    padding: 0;
  }
  .sections{
    padding: 0;
  }
  footer{
    padding: 0;
    width: 100%;
  }
  .footer1{
    display: flex;
    flex-direction: column;
  }
  .footer11{
    width: 100%;
    text-align: center;
    font-size: 10px;
  }
  .footerSM{
    text-align: center;
    flex-direction: column;
  }
  .footer2{
    font-size: 10px;
  }
  .contacttContainer{
    width: 95%;
    display: block;
    height: 550px;
    font-size: 15px;
  }
  .linkedinLink,.githubLink{
    margin: 5px;
  }
  .footerheader{
    font-size: 18px;
  }
  .footerp{
    font-size: 10px;
  }
}




@media (min-width: 765px) and (max-width: 992px){
  .heading{
    font-size: 60px;
    font-weight: bold;
    width: 500px;
  }
  .headerp{
    width: 600px;
  }
  .aboutme{
    font-size: 50px;
    font-weight: bold;
    width: 600px;
  }
  .gettoknowme-myskills{
    align-items: center;
    flex-direction: column;
    width: auto;
    height: auto;
  }
  .gettoknowme{
    margin-bottom: 50px;
  }
  h3{
    margin: 20px;
  }
  .myskills{
    width: 80%;
    padding-bottom: 50px;
  }
  .project{
    display: flex;
    justify-content: center;
  }
  .detail{
    width: 500px;
    height: auto;
    font-size: 12px;

  }
  .detailsheader{
    font-size: 20px;
    width: 100%;
    margin: 0;
  }
  .project1p{
    width: 100%;
    margin: 3px;
  }
 
  .projBtn{
    height: 30px;
    font-size: 10px;
    width: 120px;
    margin: 0;
    padding: 0;
  }
  .sections{
    padding: 0;
  }
  footer{
    padding: 0;
    width: 100%;
  }
  .footer11{
    width: 900px;
    padding-left: 10px;
  }
  .contacttContainer{
    width: 70%;
  }
}


@media (min-width: 576px) and (max-width: 765px){
  .heading{
    font-size: 60px;
    font-weight: bold;
    width: 500px;
  }
  .headerp{
    width: 600px;
  }
  .aboutme{
    font-size: 50px;
    font-weight: bold;
    width: 600px;
  }
  .gettoknowme-myskills{
    align-items: center;
    flex-direction: column;
    width: auto;
    height: auto;
  }
  .gettoknowme{
    margin-bottom: 50px;
  }
  h3{
    margin: 20px;
  }
  .myskills{
    width: 80%;
    padding-bottom: 50px;
  }
  .project{
    display: flex;
    justify-content: center;
  }
  .detail{
    width: 500px;
    height: auto;
    font-size: 12px;

  }
  .detailsheader{
    font-size: 20px;
    width: 100%;
    margin: 0;
  }
  .project1p{
    width: 100%;
    margin: 3px;
  }
 
  .projBtn{
    height: 30px;
    font-size: 10px;
    width: 120px;
    margin: 0;
    padding: 0;
  }
  .sections{
    padding: 0;
  }
  footer{
    padding: 0;
    width: 100%;
    height: auto;
  }
  .footer11{
    width: 900px;
    padding-left: 10px;
  }
  .contacttContainer{
    width: 85%;
  }
  .footerheader{
    font-size: 20px;
  }
}